.mt0 {
  margin-top: 0;
}
.mb0 {
  margin-bottom: 0;
}
.mb2 {
  margin-bottom: 2rem;
}

.mt5 {
  margin-top: 5px;
}
.ml5 {
  margin-left: 5px;
}
@media only screen and (min-width: 649px) {
  .ml5r {
    margin-left: 5rem;
  }
}

.mb5 {
  margin-bottom: 5px;
}
.mr5 {
  margin-right: 5px;
}
@media only screen and (min-width: 649px) {
  .mr5r {
    margin-right: 5rem;
  }
}
.mt10 {
  margin-top: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mr10 {
  margin-right: 10px;
}

.m1rem {
  margin: 1rem;
}

.m2rem {
  margin: 2rem;
}
.mt3rem {
  margin-top: 3rem !important;
}
.mb2rem {
  margin-bottom: 2rem !important;
}
.pb1rem {
  padding-bottom: 1rem !important;
}
.pb2rem {
  padding-bottom: 2rem !important;
}

.pSides05rem {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pSides2rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.w100 {
  width: 100%;
}

.h725 {
  min-height: 725px;
}
.h100 {
  min-height: 100vh;
  height: 100px;
}

.w1024 {
  width: 1024px;
}
.w1280 {
  width: 1280px;
}

@media only screen and (max-width: 649px) {
}

.aviso-portada {
  border: 2px solid var(--mainColor);
  border-radius: 2px;
  background-color: var(--menuBg);
  padding: 1rem;
  margin: 1rem auto;
  max-width: 90%;
  width: fit-content;
}
.aviso-portada h1,
p {
  text-align: center !important;
}

@media only screen and (max-device-width: 649px) {
  .aviso-portada {
    margin: 3rem auto;
  }
}

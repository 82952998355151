.btn,
.btn-invert {
  outline: none;
  display: inline-block;
  background: var(--secondColor);
  color: white;
  padding: 0.5rem 1.75rem;
  font-family: Lighter;
  font-size: var(--textSize);
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid var(--secondColor);
  border-radius: 25px;
  transition-property: border, background, color;
  transition-duration: 0.3s;
  text-decoration: none;
  z-index: 1000 !important;
}

.btn:hover {
  border: 1px solid white;
  background: transparent;
}

.btn-invert:hover {
  border: 1px solid var(--secondColor);
  background: transparent;
  color: var(--secondColor);
}

.btn:disabled {
  color: grey;
  border: 1px solid grey;
  background: transparent;
}

.btn-link {
  position: relative;
  font-family: Regular;
  font-size: calc(var(--textSize) * (1.05));
  color: var(--secondColor);
  text-decoration: none;
  margin: 0 0 1rem;
  line-height: 2.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.btn-link-auth {
  color: var(--secondColor);
  text-decoration: none;
  margin: 0 0.5rem;
}

.btn-link .line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.btn-link .line span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0.25vw;
  border-bottom: 2px solid var(--secondColor);
}

.btn-link .line span:first-child {
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0.2s;
}

.btn-link .line span:last-child {
  transform: translateX(-102%);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.btn-link:hover .line span:first-child {
  transform: translateX(102%);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.btn-link:hover .line span:last-child {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
}

.btn-menu,
.btn-signOut {
  border: none;
  background: transparent;
  font-size: 2rem;
  color: var(--secondColor);
  padding: 1.3rem 1.5rem;
  cursor: pointer;
  outline: none;
}

.btn-signOut {
  color: var(--mainColor);
  font-size: 1.75rem;
}

.rm-wrapper {
  position: fixed;
  z-index: 1000 !important;
}
.rm-item {
  background: var(--secondColor);
  border: 1px solid var(--mainColorDark);
}

.rm-txt {
  font-family: Lighter;
  font-size: var(--textSize);
  line-height: 1;
}

.app-root {
  position: relative;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  background: white;
  flex: 1 0 auto;
  padding: 4rem 2rem 0;
}

.app-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 650px;

  @media screen {
  }
}
.leo-bg-img {
  max-width: 100%;
}

.app-section {
  position: relative;
  margin: 0;
  padding: 0;
}

.app-section-0,
.app-section-1,
.app-section-2,
.app-signIn {
  padding: 5rem 0 0;
}

.app-section-0 {
  padding-bottom: 4rem;
}

.app-section-1 {
  height: auto !important;
  min-height: auto;
  background: url('../../assets/imgs/logo.jpg') -100px -50px no-repeat
    var(--mainColor);
}

.app-section-2 {
  height: auto !important;
  min-height: auto;
  padding: 0;
}

.app-signIn {
  flex: 1 0 auto;
}

.app-section-width,
.app-section-boxes {
  position: relative;
  max-width: 1024px;
  margin: 0 auto !important;
}

.app-section-boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-section-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem 1.5rem;
  box-sizing: border-box;
}

.app-admin-title {
  display: flex;
  align-items: center;
  border-bottom: 1px dotted var(--textColor);
  padding: 2rem 0 0;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}

.app-admin-title h1 {
  flex: 1 0 auto;
  font-family: Lighter;
  font-size: var(--maxSize);
}

.app-admin-header {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.app-admin-admin-tools {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-list-button {
  margin: 1rem;
}
.app-list-button_last {
  margin: 0;
}

@media only screen and (max-width: 850px) {
  .app-section-boxes {
    flex-direction: column;
  }

  .app-section-box {
    width: 100%;
  }
}

@media only screen and (max-width: 649px) {
  .app-admin-title {
    flex-direction: column;
    padding-bottom: 1rem;
    text-align: center;
  }

  .app-list-button {
    margin-right: 0rem;
    margin-bottom: 0.5rem;
  }
  .app-content {
    padding: 4rem 1rem;
    width: 96%;
  }
}

.hr-margin {
  margin: 3rem auto;
  width: 40%;
}

:root {
  --textColor: #333333;
  --mainColor: #612559;
  --mainColorDark: #40173a;
  --secondColor: #6caa85;
  --errorColor: #ff3366;
  --warningColor: #ede04d;
  --menuBg: #f4f7f6;
  --titleSize: 3.5rem;
  --hugeSize: 2.5rem;
  --maxSize: 1.75rem;
  --textSize: 1rem;
  --tinySize: 0.8rem;

  /* Header */
  --headerImgSize: 100%;
}

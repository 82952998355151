.app-form {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  z-index: 1;
}

.app-form-group,
.app-form-whole {
  position: relative;
  display: inline-block;
  min-height: 5rem;
  width: 50%;
  padding: 1rem;
  box-sizing: border-box;
}

.app-form-whole {
  width: 100%;
}

.app-form-accept {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 5rem;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.app-form-label {
  color: white;
  font-size: var(--minSize);
  font-family: Lighter;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  display: none;
}

.app-form-label-txt {
  color: white;
  font-size: var(--tinySize);
  font-family: Regular;
  display: inline-block;
  max-width: 100%;
  margin: 0 0 1.5rem 0.5rem;
  display: block;
}

.app-form-input,
.app-form-input-with-button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.8rem 0.75rem;
  font-size: var(--textSize);
  font-family: Lighter;
  line-height: 1.25;
  color: white;
  background-color: var(--mainColorDark);
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid var(--mainColorDark);
  border-radius: 0.25rem;
  -webkit-transition:
    border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition:
    border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.app-form-input::placeholder {
  font-family: Lighter;
  font-size: var(--textSize);
  color: #f1f1f1;
}

.app-select {
  padding: 1rem;
  width: 100%;
  height: 2rem;
  min-height: 2rem;
  font-size: var(--minSize);
  background: white;
  color: var(--bgColor);
}

.app-range {
  width: 80%;
  margin-right: 0.5rem;
}

.app-form-input-with-button {
  display: inline-block;
  width: 78%;
  box-sizing: border-box;
}

.app-form-input-button {
  width: 18%;
  float: right;
  padding: 0.5rem 0;
}

textarea:focus,
input:focus {
  outline: none;
}

input.active:valid,
textarea.active.valid {
  border: 1px solid var(--secondColor);
}

input.active:invalid,
textarea.active.invalid {
  border: 1px solid var(--errorColor);
}

.app-form-error {
  position: absolute;
  right: 1rem;
  top: 3.6rem;
  font-family: Lighter;
  color: var(--errorColor);
  font-size: var(--tinySize);
  margin: 2px 0 0 0;
}

.app-form-label-txt-error {
  position: absolute;
  top: 1.5rem;
  font-family: Lighter;
  color: var(--errorColor);
  font-size: var(--tinySize);
}

.app-form-checkbox {
  display: inline-block;
  width: 5rem;
  height: 26px;
  background: var(--mainColorDark);
  margin: 0 1rem 0.5rem 0;
  position: relative;
  border-radius: 50px;
  box-shadow:
    inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.2);
}

.app-form-checkbox:before {
  content: 'SI';
  color: var(--secondColor);
  position: absolute;
  left: 10px;
  z-index: 0;
  font:
    12px/26px Arial,
    sans-serif;
  font-weight: bold;
}

.app-form-checkbox:after {
  content: 'NO';
  color: #000;
  position: absolute;
  right: 10px;
  z-index: 0;
  font:
    12px/26px Arial,
    sans-serif;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
}

.app-form-checkbox label {
  display: block;
  width: 3rem;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  font-family: Regular;
  font-size: var(--textSize);
  z-index: 1;
  background: var(--textColor);
  color: white;
  border-radius: 50px;
  transition:
    left 0.4s ease,
    background 0.4s ease,
    color 0.4s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 1.2;
}

.app-form-checkbox input[type='checkbox'] {
  visibility: hidden;
}

.app-form-checkbox input[type='checkbox']:checked + label {
  left: 43px;
  background: var(--secondColor);
  color: white;
}

@media only screen and (max-width: 649px) {
  .app-form-group {
    display: block;
    width: 100%;
    padding-bottom: 0;
  }
}

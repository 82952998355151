.grid-row {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 767px) {
  .grid-row {
    display: flex;

    flex-direction: column;
  }
}

.mini-grid-row {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: end;
  align-items: flex-start;
}

.mini-grid-margin {
  margin-left: 1rem;
}

.mini-grid-item-center {
  align-self: center;
}

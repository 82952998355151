.app-background {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 725px;
  overflow: hidden;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: linear;
}

.app-background-render {
  opacity: 1;
}

@media only screen and (min-width: 850px) {
  .app-background img {
    background-size: cover !important;
    position: relative;
    width: 125%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .app-background.app-header-bg img {
    width: var(--headerImgSize);
    height: 100%;
  }
}

@media only screen and (max-width: 849px) {
  .app-background img {
    background-size: cover !important;
    position: relative;
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-device-width: 849px) and (orientation: landscape) {
  .app-background img {
    background-size: cover !important;
    position: relative;
    width: auto;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

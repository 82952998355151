.app-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.app-loader-icon {
  font-size: var(--maxSize);
  color: var(--textColor);
  -webkit-animation: app-loader-rotation 0.6s infinite linear; /* Safari 4+ */
  -moz-animation: app-loader-rotation 0.6s infinite linear; /* Fx 5+ */
  -o-animation: app-loader-rotation 0.6s infinite linear; /* Opera 12+ */
  animation: app-loader-rotation 0.6s infinite linear;
}

.app-loader-msg {
  font-family: Lighter;
  font-size: var(--minSize);
}

@-webkit-keyframes app-loader-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes app-loader-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes app-loader-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes app-loader-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

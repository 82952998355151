p,
.txt,
ul > li {
  font-family: Regular;
  font-size: var(--textSize);
  color: var(--textColor);
  line-height: var(--maxSize);
  text-align: left;
}

ul.more-space {
  margin: 22px 0;
}

ul.no-bullets {
  list-style-type: none; /* No bullet for specific list items */
}

.txt-IE {
  flex: 1 1 auto;
  width: 100%;
}

.txt-inline {
  display: inline;
}

.txt-message {
  display: block;
  width: 80%;
  margin: 0 auto 4rem;
  padding: 1rem;
  color: var(--mainColorDark);
  background: var(--menuBg);
  border: 1px solid var(--mainColorDark);
  font-family: Regular;
  text-align: center;
  border-radius: 5px;
}

.txt-subtitle {
  font-family: Bold;
  color: var(--mainColorDark);
  text-transform: uppercase;
}

.txt-highlight {
  font-family: Bold;
}

.txt-italic {
  display: inline-block;
  font-family: Lighter;
  font-style: italic;
  line-height: 1.75;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left !important;
}

.tit-header {
  position: relative;
  font-family: Regular;
  font-size: var(--titleSize);
  color: var(--secondColor);
  text-align: center;
  padding: 11rem 0 1.5rem;
  margin: 0;
  z-index: 1;
  line-height: 3rem;
}

.header-wrapper {
  position: relative;
  z-index: 1;
  text-align: center;
}

.header-wrapper > * {
  padding: 0;
  margin: 0;
}

.tit-section {
  color: white;
  padding: 2rem 0 0;
  font-family: Lighter;
  font-size: var(--hugeSize);
}

.tit-section-secondColor {
  color: var(--secondColor);
}

.subtit-section {
  padding: 0 0 2rem;
  margin: 0;
  font-size: 1.75rem;
  font-family: Bold;
  color: var(--secondColor);
}
.warning-color {
  color: var(--errorColor);
}

.subtit-section > small {
}

.subtit-section-underline {
  border-bottom: 1px dotted;
  margin-bottom: 2rem;
}

.red-text {
  color: tomato;
  text-align: center;
}

.subtit-section-red {
  color: tomato;
  text-align: center;
  font-family: Lighter;
  font-size: var(--hugeSize);
  border-bottom: 1px dotted;
  margin-bottom: 2rem;
}

.tit-box,
.tit-subsection {
  color: var(--textColor);
  font-family: Bold;
  font-size: var(--maxSize);
  margin-bottom: 0;
}

.subtit-header {
  font-family: Lighter;
  font-size: var(--maxSize);
  color: var(--mainColor);
  margin-bottom: 0;
  text-align: center;
}

@media only screen and (max-width: 850px) {
  .tit-header {
    font-size: var(--hugeSize);
    line-height: 2.5rem;
  }

  .tit-section {
    line-height: 2.5rem;
  }
}

.decoration-none {
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.margin-text {
  margin-bottom: 2.5rem;
}
